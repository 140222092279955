<nb-card>
  <nb-card-header>Change password</nb-card-header>
  <nb-card-body>

    <div class="form-group">
      <label for="oldpass" class="label">Please enter your old password*</label>
      <input #oldpass nbInput fullWidth type="password" id="oldpass">
    </div>

    <div class="form-group">
      <label for="newpass" class="label">Please enter your new password*</label>
      <input #newpass nbInput fullWidth type="password" id="newpass">
    </div>

    <div class="form-group">
      <label for="comparepass" class="label">Please re-enter your new password*</label>
      <input #comparepass nbInput fullWidth type="password" id="comparepass">
    </div>

  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" nbButton status="danger" (click)="cancel()">Cancel</button>
    <button nbButton status="success" (click)="submit(oldpass.value, newpass.value, comparepass.value)">Submit</button>
  </nb-card-footer>
</nb-card>
