<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <!-- <a href="#" class="logo"  (click)="navigateHome()"><span>Borderpass</span></a> -->
    <img src="./assets/images/NewLogo.png" href="#" class="logo">
  </div>
</div>


<div class="header-container">
  <nb-actions size="small">
    <nb-action title="Change password" (click)="changePass()" class="control-item" icon="lock-outline" ></nb-action>
    <nb-action title="Logout" (click)="logout()" class="control-item" icon="log-out-outline"></nb-action> -->
  </nb-actions>
</div> 